import React, { Fragment } from 'react';
import {
  Switch, Route, withRouter, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActiveLanguage } from 'react-localize-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

import * as actions from '../actions/actions';
import Header from '../components/Header';
import LoadingSuspense from '../components/shared/LoadingSuspense';
import BoughtPopupsComponent from './CartChangesContainer';
import MainSnackBar from '../components/shared/MainSnackBar';
import RightSidebarMenuContainer from './RightSidebarMenuContainer';
import LeftSidebarMenuContainer from './LeftSidebarMenuContainer';
import FaqPopup from '../components/shared/faqPopup/FaqPopup';
import TrackerContainer from './TrackerContainer';
import CatalogTopBanner from '../components/catalog/CatalogTopBanner';
import CatalogDeliveryComponent from '../components/catalog/catalogDelivery/CatalogDeliveryComponent';
import ProductContainer from './ProductContainer';
import AppBar from '../components/app-bar/AppBar';

// NO lazy components to prevent ssr rerender on client
/*
const CatalogContainer = React.lazy(() => import('./CatalogContainer'));
const LoginContainer = React.lazy(() => import('./LoginContainer'));
const CartContainer = React.lazy(() => import('./CartContainer'));
const FooterContainer = React.lazy(() => import('./FooterContainer'));
// const ProductContainer = React.lazy(() => import('./ProductContainer'));
const AuthorizationContainer = React.lazy(() => import('./AuthorizationContainer'));
const StoresContainer = React.lazy(() => import('./StoresContainer'));
const ProfileContainer = React.lazy(() => import('./ProfileContainer'));
const MemorizedNewsList = React.lazy(() => import('./NewsListContainer'));
const MemorizedNewsPage = React.lazy(() => import('./NewsPageContainer'));
const FourZeroFourth = React.lazy(() => import('../components/404'));
const FaqContainer = React.lazy(() => import('./FaqContainer'));
const DeliveryOrTakeoutContainer = React.lazy(() => import('./DeliveryOrTakeoutContainer'));
const CheckoutContainer = React.lazy(() => import('./CheckoutContainer'));
const TextPagesContainer = React.lazy(() => import('./TextPagesContainer'));
// const BoughtPopupsComponent = React.lazy(() => import('../components/shared/boughtPopup/BoughtPopupsComponent'));
const StoreContainer = React.lazy(() => import('./StoreContainer'));
const MemoPromotionsContainer = React.lazy(() => import('./PromotionsContainer'));
const ThankYouContainer = React.lazy(() => import('./ThankYouContainer'));
const JobOffersContainer = React.lazy(() => import('./JobOffersContainer'));
const AboutUsPage = React.lazy(() => import('../components/text-pages/AboutUsPage'));
const BonusesPage = React.lazy(() => import('../components/text-pages/BonusesPage'));
*/

import CatalogContainer from './CatalogContainer';
import LoginContainer from './LoginContainer';
import CartContainer from './CartContainer';
import FooterContainer from './FooterContainer';
import AuthorizationContainer from './AuthorizationContainer';
import StoresContainer from './StoresContainer';
import ProfileContainer from './ProfileContainer';
import MemorizedNewsList from './NewsListContainer';
import MemorizedNewsPage from './NewsPageContainer';
import FourZeroFourth from '../components/404';
import FaqContainer from './FaqContainer';
import DeliveryOrTakeoutContainer from './DeliveryOrTakeoutContainer';
import CheckoutContainer from './CheckoutContainer';
import TextPagesContainer from './TextPagesContainer';
import StoreContainer from './StoreContainer';
import MemoPromotionsContainer from './PromotionsContainer';
import ThankYouContainer from './ThankYouContainer';
import JobOffersContainer from './JobOffersContainer';
import BonusesPage from '../components/text-pages/BonusesPage';
import SeoRoute from '../components/layouts/SeoRoute';

const modalRoutes = ['login', 'authorization', 'cart', 'user-right-side-menu', 'products', 'crosssalemodal'];

// NO use of experimental React.Suspense
// const SsrCompatibleSuspense = process.env.IS_SERVER ? (props) => props.children : React.Suspense;
const SsrCompatibleSuspense = (props) => props.children;

// https://reacttraining.com/react-router/web/example/modal-gallery
class LayoutContainer extends React.Component {
  constructor(props) {
    super(props);

    this.prevPath = '';
    this.prevMatch = null;

    this.setShownBar = this.setShownBar.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.isBarShown !== nextProps.isBarShown) {
      return true;
    }
    const alias = this.props.history.location.pathname.slice(this.props.history.location.pathname.lastIndexOf('/') + 1);
    const categoriesAliases = this.getCategoriesAliases(nextProps.categories);
    return !(!nextProps.activeCatalogLink.shouldCatalogUpdate
      && (categoriesAliases.indexOf(alias) > -1
        || alias === '' || alias === 'favourites' || alias === 'menu'));
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== 'POP'
      && (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  componentDidUpdate() {
    const { location } = this.props;
    if (this.previousLocation && location) {
      if ((this.previousLocation.pathname.includes('/ua/') && !location.pathname.includes('/ua/'))
        || (!this.previousLocation.pathname.includes('/ua/') && location.pathname.includes('/ua/'))) {
        window.location.reload();
      }
    }
  }

  getCategoriesAliases = (categories) => categories.map((category) => category.alias)

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  setShownBar(bool) {
    this.props.actions.setBarShown(bool);
  }

  closeFaqPopup = () => {
    this.props.actions.setIsFaqPopupState(false);
  }

  fetchData() {
    const { history } = this.props;
    this.prevPath = history.location.pathname;
    return Promise.all([
      this.props.actions.getCategories(),
      this.props.actions.getIngredients(),
      this.props.actions.getSettings(),
      // this.props.actions.getAllergens(),
      this.props.actions.getTextPages(),
    ]);
  }

  render() {
    const {
      location, languagePrefix, categories, user, isMobile, currentLanguage, seoData,
    } = this.props;

    const baseRoutePath = location.pathname.slice(0, location.pathname.lastIndexOf('/'));

    const categoryAliasesArr = this.props.categories ? this.props.categories.map((catItem) => (
      catItem.alias ? catItem.alias : null
    )) : null;

    const paths = categoryAliasesArr && categoryAliasesArr[0]
      ? categoryAliasesArr.map((catAlias) => `${languagePrefix}/${catAlias}`) : [];

    const isModal = modalRoutes.some((route) => (
      `${languagePrefix}/${route}`) === location.pathname
      || `${languagePrefix}/${route}` === baseRoutePath
      || paths.includes(baseRoutePath));

    const CartContainerModal = () => (
      <CartContainer isModalMode {...this.props} />
    );

    const profileRoute = (
      <SeoRoute path={`${languagePrefix}/profile`} component={ProfileContainer} />
    );

    if (this.props.history.location.pathname === `${languagePrefix}/profile` && user && user.code === 'ERROR') {
      this.props.history.push(`${languagePrefix}/`);
    }

    const catalogPaths = [
      `${languagePrefix}/`,
      `${languagePrefix}/products/:id`,
      `${languagePrefix}/favourites`,
      ...paths,
    ];

    const SeoDataFragment = () => (seoData && seoData[0]
      ? (
        <>
          <Helmet>
            <title>{seoData[0][`title_${currentLanguage}`]}</title>
            <meta name="title" content={seoData[0][`meta_title_${currentLanguage}`]} />
            <meta name="description" content={seoData[0][`meta_description_${currentLanguage}`]} />
            <meta name="keywords" content={seoData[0][`meta_keywords_${currentLanguage}`]} />
          </Helmet>
        </>
      )
      : null);

    return (
      <>
        <AppBar setShownBar={this.setShownBar} />
        <StyledApp
          id="app"
          lang={currentLanguage === 'ua' ? 'uk' : 'ru'}
          isBarShown={this.props.isBarShown}
        >
          <SeoDataFragment />
          <header className="header">
            <TrackerContainer />
            <Header
              user={this.props.user}
              openSidebarMenu={this.props.openSidebarMenu}
              scrollToTop={this.scrollToTop}
              isMobile={isMobile}
              catalogPaths={catalogPaths}
            />
          </header>
          <Switch>
            <SeoRoute
              exact
              path={catalogPaths}
              render={(props) => (
                <>
                  <CatalogTopBanner {...props} location={location} catalogPaths={catalogPaths} />
                  <CatalogDeliveryComponent {...props} />
                </>
              )}
            />
          </Switch>
          {/* <CatalogTopBanner */}
          {/* user={user} */}
          {/* /> */}
          {/* <div className="delivery-wrap"> */}
          {/* <CatalogDeliveryComponent */}
          {/* user={user} */}
          {/* /> */}
          {/* </div> */}
          {/* <CatalogDeliveryComponent */}
          {/* user={user} */}
          {/* /> */}
          <RightSidebarMenuContainer />
          <LeftSidebarMenuContainer />
          <StyledMain className="border-solid">
            <SsrCompatibleSuspense fallback={<LoadingSuspense />}>
              {isModal ? (
                <Switch>
                  <SeoRoute path={`${languagePrefix}/login`} component={LoginContainer} />
                  <SeoRoute path={`${languagePrefix}/authorization`} component={AuthorizationContainer} />
                  <SeoRoute path={`${languagePrefix}/cart`} component={CartContainerModal} />
                </Switch>
              ) : null}
              <Switch>
                <SeoRoute
                  exact
                  path={catalogPaths}
                  component={CatalogContainer}
                />
                <SeoRoute exact path={[`${languagePrefix}/products/:number`, ...paths.map((p) => `${p}/:number`)]} component={ProductContainer} />
                <SeoRoute exact path={`${languagePrefix}/stores/:number`} component={StoreContainer} />
                <SeoRoute strict path={`${languagePrefix}/stores`} component={StoresContainer} />
                {profileRoute}
                <SeoRoute exact path={`${languagePrefix}/news`} component={MemorizedNewsList} />
                <SeoRoute path={`${languagePrefix}/news/:alias`} component={MemorizedNewsPage} />
                <SeoRoute exact path={`${languagePrefix}/feedback`} component={FaqContainer} />
                <SeoRoute exact path={`${languagePrefix}/vacancies`} component={JobOffersContainer} />
                <SeoRoute
                  exact
                  path={[`${languagePrefix}/pages/collect-bonuses`, `${languagePrefix}/pages/spend-bonuses`]}
                  component={BonusesPage}
                />
                <SeoRoute exact path={`${languagePrefix}/pages/:page`} component={TextPagesContainer} />
                <SeoRoute exact path={`${languagePrefix}/thankyou`} component={ThankYouContainer} />
                <SeoRoute exact path={`${languagePrefix}/deliveryOrTakeout`} component={DeliveryOrTakeoutContainer} />
                <SeoRoute exact path={`${languagePrefix}/checkout`} component={CheckoutContainer} />
                <SeoRoute exact path={`${languagePrefix}/promotions`} component={MemoPromotionsContainer} />
                <SeoRoute component={() => (<FourZeroFourth {...this.props} />)} />
              </Switch>
              {/* render index path in initial load with modal */}
            </SsrCompatibleSuspense>
          </StyledMain>
          {/* rendering foter for all, except 404 and stores */}
          <SsrCompatibleSuspense fallback={<LoadingSuspense />}>
            <Switch>
              {/* <SeoRoute path={`${languagePrefix}/stores/`} component={FooterContainer} /> */}
              <SeoRoute path={`${languagePrefix}/stores/:number`} component={FooterContainer} />
              <SeoRoute exact path={`${languagePrefix}/ui`} component={FooterContainer} />
              <SeoRoute exact path={`${languagePrefix}/news`} component={FooterContainer} />
              <SeoRoute path={`${languagePrefix}/news/:number`} component={FooterContainer} />
              <SeoRoute exact path={`${languagePrefix}/deliveryOrTakeout`} component={FooterContainer} />
              <SeoRoute exact path={`${languagePrefix}/deliveryOrTakeout`} component={FooterContainer} />
              {/* <SeoRoute exact path={`${languagePrefix}/checkout`} component={FooterContainer} /> */}
            </Switch>
            {/* isModal && !this.previousLocation ? (
            <Redirect to={`${languagePrefix}/`} />
            // <SeoRoute path={`${languagePrefix}/`} component={CatalogContainer} />
          ) : null */}
          </SsrCompatibleSuspense>
          <div className="bought-popups">
            <BoughtPopupsComponent />
          </div>
          <MainSnackBar />
          <FaqPopup removePopup={this.closeFaqPopup} />
        </StyledApp>
      </>
    );
  }
}


const StyledApp = styled.div`
  min-height: ${(props) => (props.isBarShown ? 'calc(100vh - 60px)' : '100vh')};
  display: flex;
  flex-direction: column;
  
  .bought-popups {
    position: fixed;
    bottom: 3rem;
    left: 3rem;
    z-index: 14;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    position: sticky;
    top: ${(props) => (props.isBarShown ? '60px' : '0')};
    left: 0;
    width: 100%;
    z-index: 9;
  }
  
  .delivery-wrap {
    //position: -webkit-sticky;
    //position: sticky;
    //height: 0;
    //z-index: 12;
    //height: 0;
    ///*margin-top: -70px;
    //height: 70px;*/
    //display: none;
    //top: 6rem;
  }
  
  @media all and (min-width: 768px) {
    .delivery-wrap {
      display: block;
    }
  }
`;

const StyledMain = styled.main`
  display: flex;
  max-width: 100%;
  background-color: #0a0a0a;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledAppBar = styled.main`
  display: flex;
  max-width: 100%;
  background-color: #0a0a0a;
  flex-direction: column;
  flex-grow: 1;
`;

const mapStateToProps = (state) => ({
  categories: state.categories ? state.categories : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  activeCatalogLink: state.activeCatalogLink ? state.activeCatalogLink : null,
  isMobile: state.isMobile,
  user: state.user ? state.user : null,
  seoData: state.seoHomePage,
  isBarShown: state.appBar,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutContainer));
