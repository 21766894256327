/* eslint-disable no-param-reassign,operator-assignment,no-prototype-builtins,consistent-return */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';
import * as storage from '../../utils/storage';
import AuthorizationSteps from '../authorization/AuthorizationSteps';
import CheckoutPaymentComponent from './payment/CheckoutPaymentComponent';
import Hr from '../shared/Hr';
import CheckoutDelivery from './delivery/CheckoutDelivery';

import checkoutBanner from '../../assets/images/checkoutBanner.jpg';
import * as actions from '../../actions/actions';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';
import keyByLang from '../../modules/shared/keyByLang';
import fixImagePath from '../../modules/shared/fixImagePath';
import { setIsFaqPopupState } from "../../actions/actions";

class CheckoutPage extends React.Component {
  static formatTimeAverage(data) {
    return +data < 10 ? `0${data}` : data;
  }

  static filterZeroAccessories(data) {
    const result = { ...data };
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (result[key] === 0) {
        delete result[key];
      }
    });
    return CheckoutPage.setToString(result);
  }

  static countExtraPrice(extraAccessories, accessories) {
    return accessories.reduce((res, accessorie) => {
      res += extraAccessories[accessorie.id]
        ? extraAccessories[accessorie.id]
        * accessorie.price : 0;
      return res;
    }, 0);
  }

  constructor(props) {
    super(props);

    this.state = {
      isDeliveryChosen: CheckoutPage.setDeliveryState(),
      sendingData: false,
      orderAfterPromocode: null,
      promoState: false,
      deliveryAddress: {},
    };

    this.storeRef = React.createRef();
    this.deliveryFormRef = React.createRef();

    this.submitDelivery = this.submitDelivery.bind(this);
    this.editDelivery = this.editDelivery.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.checkoutHandleSubmit = this.checkoutHandleSubmit.bind(this);
    this.checkPrice = this.checkPrice.bind(this);
    this.getPriceWithBonuses = this.getPriceWithBonuses.bind(this);
    this.changeButtonStatus = this.changeButtonStatus.bind(this);
    this.changeOrderAfterPromo = this.changeOrderAfterPromo.bind(this);
    this.changePromoStatus = this.changePromoStatus.bind(this);
    this.createOrderToSend = this.createOrderToSend.bind(this);
    this.setOrderId = this.setOrderId.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    const savedOrder = JSON.parse(storage.session.getItem('orderData'));
    const newAccessories = JSON.parse(storage.session.getItem('orderAccessories'));
    if (this.props.order && this.props.order.shouldAccessoriesUpdate
      && this.props.order.freeAccessoriesByTypes) {
      savedOrder.accessories = this.props.order.freeAccessoriesByTypes;
      savedOrder.free_accessories = this.props.order.freeAccessories;
      savedOrder.extra_accessories = this.props.order.extraAccessories;
      storage.session.setItem('orderData', JSON.stringify(savedOrder));
      return;
    }
    if (savedOrder && newAccessories) {
      savedOrder.free_accessories = newAccessories.free_accessories;
      savedOrder.extra_accessories = newAccessories.extra_accessories;
      savedOrder.accessories = newAccessories.accessories;
      storage.session.setItem('orderData', JSON.stringify(savedOrder));
    } else if (!savedOrder && newAccessories) {
      const newOrder = CheckoutPage.setInitOrder(newAccessories);
      storage.session.setItem('orderData', JSON.stringify({ ...newOrder }));
    }
  }

  componentWillUnmount() {
    this.changeButtonStatus(false);
  }

  static setDeliveryState() {
    const order = JSON.parse(storage.session.getItem('orderData'));
    if (!order || !order.Orders || !order.Orders.delivery_method_id) {
      return false;
    }
    const deliveryData = JSON.parse(storage.session.getItem('deliveryAddress'));
    const takeOutStore = JSON.parse(storage.session.getItem('takeOutStore'));
    const lastDeliveryType = JSON.parse(storage.local.getItem('lastDeliveryType'));
    let deliveryId;
    if (lastDeliveryType) {
      deliveryId = lastDeliveryType === 'takeOutStore' ? 1 : 2;
    }
    if (order && order.Orders && +order.Orders.delivery_method_id !== deliveryId) {
      return order.Orders.delivery_method_id === deliveryId;
    }
    if (deliveryId === 2 && order && order.Orders) {
      return order.Orders.address.includes(deliveryData.address);
    }
    if (deliveryId === 1 && order && order.Orders) {
      return order.Orders.address === takeOutStore.address.address_ru
        || order.Orders.address === takeOutStore.address.address_ua;
    }
    return order && order.Orders && order.Orders.delivery_method_id;
  }

  static setToString(data) {
    const result = { ...data };
    const keys = Object.keys(result);
    keys.forEach((key) => {
      result[key] = result[`${key}`].toString();
    });
    return result;
  }

  static setInitOrder(order) {
    const initOrder = {};
    if (order && order.free_accessories) {
      initOrder.free_accessories = order.free_accessories;
      initOrder.extra_accessories = order.extra_accessories;
      initOrder.accessories = order.accessories;
      return initOrder;
    }
    return initOrder;
  }

  static getTimeToReady(date, additionalTime) {
    let minutes = date.getMinutes() + +additionalTime;
    let hours = date.getHours();
    const seconds = CheckoutPage.formatTimeAverage(date.getSeconds());
    if (minutes >= 60) {
      const difference = Math.floor(minutes / 60);
      hours = hours + difference;
      minutes = minutes - difference * 60;
    }
    hours = CheckoutPage.formatTimeAverage(hours);
    minutes = CheckoutPage.formatTimeAverage(minutes);
    return `${hours}:${minutes}:${seconds}`;
  }

  getCurrentTime(deliveryMethodId) {
    let additionalTime = 0;
    const slot = this.props.timeSlots[0];
    if (+deliveryMethodId === 1) {
      additionalTime = slot.order_total_time_pickup;
    } else if (+deliveryMethodId === 2) {
      additionalTime = slot.order_total_time_delivery;
    }
    const now = new Date();
    const month = CheckoutPage.formatTimeAverage(now.getMonth() + 1);
    const day = CheckoutPage.formatTimeAverage(now.getDate());
    const time = CheckoutPage.getTimeToReady(now, additionalTime);
    return `${now.getFullYear()}-${month}-${day} ${time}`;
  }

  getPriceWithBonuses(buttonPrice, formData) {
    let errors = null;
    if (formData && formData.syncErrors) {
      errors = Object.keys(formData.syncErrors);
    }
    if (+formData.values.bonuses_used > buttonPrice && !errors) {
      this.props.actions.showSnackBar(
        'Количество бонусов превышает сумму заказа.',
        4000,
        false,
      );
      return;
    }
    if (formData && !errors) {
      return buttonPrice - formData.values.bonuses_used;
    }
    if (errors) {
      return buttonPrice;
    }
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getUser(),
      this.props.actions.getAccessories(),
    ]);
  }

  setDeliveryAddress = (address) => {
    this.setState(() => ({
      deliveryAddress: { ...address },
    }));
  }

  setOrderId(id) {
    storage.session.setItem('orderId', JSON.stringify(id));
  }

  convertDeliveryZonesFormat = (arr) => {
    const filteredArr = arr.split(';').filter((item) => item);
    return filteredArr.map((item) => {
      const itemElem = item && item.split(',');
      return { lat: parseFloat(itemElem[0]), lng: parseFloat(itemElem[1]) };
    });
  }

  submitDelivery(data, price) {
    const { translate, user } = this.props;
    const minPrice = this.props.settings ? this.props.settings.min_order_value : 0;
    if (data.type === 2 && this.props.settings && price < minPrice) {
      this.props.actions.showSnackBar(
        `${translate('MinDeliverySum')}: ${minPrice} грн. ${translate('MinDeliverySum2')}: ${minPrice - price} грн.`,
        4000,
        false,
      );
      return;
    }
    let addressValue = null;
    let orderData = {};
    const orders = {};
    if (!data.address) {
      const { address } = this.state.deliveryAddress;
      if (data.type === 1) {
        this.props.actions.showSnackBar(this.props.translate('ChooseStoreForPickupText'), 4000, false);
        return;
      }
      if (!address.street) {
        return this.props.actions.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false);
      }
    }
    if (data.type === 2) {
      const { address } = this.state.deliveryAddress;
      if (!address.street) {
        return this.props.actions.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false);
      }
      if (!address.house_number) {
        return this.props.actions.showSnackBar(this.props.translate('AddHouseNumber'), 4000, false);
      }
      let fullAddress = address.street;
      if (address.house_number) {
        fullAddress += `, ${address.house_number}`;
      }
      geocodeByAddress(fullAddress)
        .then((response) => {
          const addressResult = response[0].address_components;
          const district = addressResult.find((item) => (
            item.types.includes('sublocality')
          ));
          const street = addressResult.find((item) => (
            item.types.includes('route')
          ));
          const houseNumber = addressResult.find((item) => (
            item.types.includes('street_number')
          ));
          let nameToCompare = street.long_name;
          if (houseNumber) {
            nameToCompare += `, ${houseNumber.short_name}`;
          }
          if (!houseNumber) {
            return this.props.actions.showSnackBar(this.props.translate('AddHouseNumber'), 4000, false);
          }
          getLatLng(response[0]).then((resp) => {
            if (addressValue && addressValue.hasOwnProperty('address')) {
              addressValue = addressValue.address;
            }
            if (data.rememberAddress) {
              const dataCoord = `${resp.lat},${resp.lng}`;
              const address = {
                ...this.state.deliveryAddress,
                address: JSON.stringify(this.state.deliveryAddress.address),
              };
              if (!user.addresses.find((item) => item.coordinates === dataCoord)) {
                this.props.actions.addDeliveryAddress({
                  address: address.address,
                  coordinates: dataCoord,
                  is_favourite: 1,
                }).then(() => this.props.actions.getUser());
              }
              storage.session.setItem('deliveryAddress', JSON.stringify(address));
              storage.local.setItem('lastDeliveryType', JSON.stringify('deliveryAddress'));
            }
            orders.address = JSON.stringify({
              ...this.state.deliveryAddress.address,
              coordinates: `${resp.lat},${resp.lng}`,
            });
            orders.delivery_method_id = data.type.toString();
            if (data.address && data.address.location) {
              orders.delivery_coordinates = `${data.address.location.lat},${data.address.location.lng}`;
            }
            orderData = {
              ...JSON.parse(storage.session.getItem('orderData')),
              remember_address: data.rememberAddress || false,
              Orders: orders,
            };
            storage.session.setItem('orderData', JSON.stringify(orderData));
            this.setState({
              isDeliveryChosen: true,
            });
          });
        })
        .catch(() => this.props.actions.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false));
    }
    if (data.type === 1) {
      const savedStore = JSON.parse(storage.session.getItem('takeOutStore'));
      // const savedAddress = JSON.parse(storage.session.getItem('deliveryAddress'));
      if ((this.storeRef.current.value !== `${savedStore.address.address_ru}`)
          && (this.storeRef.current.value !== `${savedStore.address.address_ua}`)) {
        this.props.actions.showSnackBar(this.props.translate('ChooseStoreFromDropdown'), 4000, false);
        return;
      }
      if (data.rememberRestaurant) {
        if (data.address.address.id_1c !== user.favourite_restaurant_id) {
          this.props.actions.updateUser({ 'User[favourite_restaurant_id]': `${data.address.address.id_1c}` });
        }
        storage.session.setItem('takeOutStore', JSON.stringify(data.address));
        storage.local.setItem('lastDeliveryType', JSON.stringify('takeOutStore'));
      }
      orders.restaurant_id = data.address.address.id_1c;
      orders.address = data.address.address[keyByLang('address', this.props.currentLanguage)];
      orders.delivery_method_id = data.type.toString();
      orderData = {
        ...JSON.parse(storage.session.getItem('orderData')),
        remember_restaurant: data.rememberRestaurant || false,
        Orders: orders,
      };
      storage.session.setItem('orderData', JSON.stringify(orderData));
      this.setState({
        isDeliveryChosen: true,
      });
    }
  }

  editDelivery() {
    this.setState({
      isDeliveryChosen: false,
    });
  }

  createOrder(paymentValues) {
    const orderData = JSON.parse(storage.session.getItem('orderData'));
    const orderTime = JSON.parse(storage.session.getItem('orderTime'));
    orderData.Orders.user_name = paymentValues.name ? paymentValues.name : this.props.user.name;
    orderData.Orders.payment_method_id = paymentValues.paymentMethod;
    if (orderTime) {
      orderData.Orders.delivery_date = orderTime.time;
      orderData.Orders.deferred_delivery = true;
    } else {
      orderData.Orders.delivery_date = '';
      orderData.Orders.deferred_delivery = false;
    }
    if (paymentValues.comment) {
      orderData.Orders.comment = paymentValues.comment;
    }
    if (paymentValues.promocodeField) {
      orderData.Orders.promocode = paymentValues.promocodeField;
    }
    if (paymentValues.bonuses_used) {
      orderData.Orders.bonuses_used = paymentValues.bonuses_used;
    }
    if (paymentValues.change_required_from && +paymentValues.paymentMethod === 1) {
      orderData.Orders.change_required_from = paymentValues.change_required_from;
    }
    orderData.free_accessories = CheckoutPage.setToString(orderData.free_accessories);
    orderData.extra_accessories = CheckoutPage.setToString(orderData.extra_accessories);
    orderData.accessories = CheckoutPage.setToString(orderData.accessories);
    return orderData;
  }

  redirectToPage(url, shopId = '') {
    const urlArr = url.split('/');
    if (urlArr[urlArr.length - 1] === 'thankyou') {
      this.props.redirectTo('thankyou');
    } else {
      const urlToSend = url.split('?');
      const userData = urlToSend[urlToSend.length - 1].split('=');
      const userObj = { id: userData[1], shop_id: shopId };
      this.props.actions.getPaymentPage(userObj).then((res) => {
        if (res.url) {
          window.open(res.url, '_self');
        }
      });
    }
  }

  checkPrice(extraAccessories) {
    const { translate } = this.props;
    const minPrice = this.props.settings ? this.props.settings.min_order_value : 0;
    let orderPrice = 0;
    if (this.props.cart && this.props.cart.id) {
      orderPrice = this.props.cart.total;
    }
    if (extraAccessories && this.props.cart && this.props.cart.id
      && this.props.settings && this.props.accessories && this.props.accessories[0]) {
      const addPrice = CheckoutPage.countExtraPrice(extraAccessories, this.props.accessories);
      orderPrice = this.props.cart.total + addPrice;
    }
    if (minPrice > orderPrice) {
      this.props.actions.showSnackBar(
        `${translate('MinDeliverySum')}: ${minPrice} грн. ${translate('MinDeliverySum2')}: ${minPrice - orderPrice} грн.`,
        4000,
        false,
      );
      return false;
    }
    return true;
  }

  changeButtonStatus(bool) {
    this.setState(() => ({ sendingData: bool }));
  }

  changePromoStatus(bool) {
    this.setState(() => ({ promoState: bool }));
  }

  changeOrderAfterPromo(orderData) {
    this.setState(() => ({ orderAfterPromocode: orderData }));
  }

  createOrderToSend(dataToSend, formData) {
    this.props.actions.orderCreate(dataToSend).then((result) => {
      formData.reset();
      this.props.actions.getCart();
      if (!result.status) {
        this.props.actions.showSnackBar(this.props.translate('OrderNotPlacedMessage'), 4000, false);
      }
      if (result.status) {
        storage.session.removeItem('orderData');
        storage.session.removeItem('orderAccessories');
        storage.session.removeItem('orderTime');
        storage.session.removeItem('takeOutStore');
        storage.session.removeItem('deliveryAddress');
        storage.session.removeItem('orderId');
        this.props.actions.setIsFaqPopupState(true);
        this.redirectToPage(result.return_url, result.shop_id);
      }
    });
  }

  checkoutHandleSubmit(values, func, formData) {
    const collectedOrder = this.createOrder(values);
    if (collectedOrder.Orders.delivery_method_id === '2' && !this.checkPrice(collectedOrder.extra_accessories)) {
      return;
    }
    if (Object.keys(collectedOrder.free_accessories).length === 0
      && Object.keys(collectedOrder.extra_accessories).length > 0) {
      this.props.actions.showSnackBar(
        this.props.translate('OrderNotPlacedMessageAdditional'),
        4000,
        false,
      );
      return;
    }
    this.changeButtonStatus(true);
    const orderId = storage.session.getItem('orderId') ? storage.session.getItem('orderId') : '';
    if (!this.state.orderAfterPromocode) {
      if (this.state.promoState) {
        this.props.actions.orderPreorder({ ...collectedOrder, order_id: orderId }).then((res) => {
          if (!res.status) {
            this.props.actions.showSnackBar(res.message[this.props.currentLanguage], 4000, false);
            this.changeButtonStatus(false);
            this.changePromoStatus(false);
          } else {
            this.changeOrderAfterPromo(res);
            this.changePromoStatus(false);
            this.changeButtonStatus(false);
          }
          this.setOrderId(res.order_id);
        });
      } else {
        this.props.actions.orderPreorder({ ...collectedOrder, order_id: orderId }).then((res) => {
          if (!res.status) {
            this.props.actions.showSnackBar(res.message[this.props.currentLanguage], 4000, false);
            this.changeButtonStatus(false);
            this.setOrderId(res.order_id);
          } else {
            this.createOrderToSend({ ...collectedOrder, order_id: res.order_id }, formData);
          }
        });
      }
    } else {
      this.createOrderToSend({ ...collectedOrder, order_id: orderId }, formData);
      this.changeButtonStatus(false);
    }
  }

  render() {
    const {
      currentLanguage,
      waitSms,
      smsValid,
      user,
      order,
      stores,
      settings,
      form,
      deliveryMethods,
      paymentMethods,
      cart,
      timeSlots,
      isMobile,
      accessories,
      languagePrefix,
      translate,
      promocodeValue,
    } = this.props;
    const { isDeliveryChosen, sendingData, orderAfterPromocode } = this.state;
    const { checkoutPaymentForm } = this.props.form;

    let buttonPrice = 0;

    if (cart && cart.id) {
      buttonPrice = cart.total;
    }

    const orderData = JSON.parse(storage.session.getItem('orderData'));

    if (cart && cart.id && settings && orderData
      && orderData.extra_accessories && accessories && accessories[0]) {
      const addPrice = CheckoutPage.countExtraPrice(orderData.extra_accessories, accessories);
      buttonPrice = cart.total + addPrice;
    }

    if (checkoutPaymentForm && checkoutPaymentForm.values
      && checkoutPaymentForm.values.bonuses_used) {
      buttonPrice = this.getPriceWithBonuses(buttonPrice, checkoutPaymentForm);
    }

    if (orderAfterPromocode) {
      buttonPrice = orderAfterPromocode.total;
    }

    let bannerImagePath = null;

    if (settings && settings.syspages) {
      const pageData = settings.syspages.find((page) => page.page_const === 'checkout');
      bannerImagePath = fixImagePath(pageData);
    }

    return (
      <StyledCheckoutPage className="page checkout" headerImage={bannerImagePath || checkoutBanner}>
        <div className="checkout__header" />
        <div className="checkout__content">
          <h1 className="checkout__heading">{translate('CheckoutOrder')}</h1>
          <Hr />
          <AuthorizationSteps
            currentLanguage={currentLanguage}
            waitSms={waitSms}
            smsValid={smsValid}
            user={user}
            actions={this.props.actions}
            form={form}
            isCheckoutPage
            isMobile={isMobile}
            translate={translate}
          />
          <Hr dotted />
          <CheckoutDelivery
            currentLanguage={currentLanguage}
            user={user}
            stores={stores}
            buttonPrice={buttonPrice}
            submitDelivery={this.submitDelivery}
            showSnackBar={this.props.actions.showSnackBar}
            deliveryMethods={deliveryMethods}
            isDeliveryChosen={isDeliveryChosen}
            editDelivery={this.editDelivery}
            timeSlots={timeSlots}
            storeRef={this.storeRef}
            form={form}
            order={order}
            translate={translate}
            setDeliveryAddress={this.setDeliveryAddress}
          />
          <Hr dotted />
          <CheckoutPaymentComponent
            user={user}
            isDeliveryChosen={isDeliveryChosen}
            paymentMethods={paymentMethods}
            currentLanguage={currentLanguage}
            checkoutHandleSubmit={this.checkoutHandleSubmit}
            changePromoStatus={this.changePromoStatus}
            settings={settings}
            showSnackBar={this.props.actions.showSnackBar}
            form={form}
            buttonPrice={buttonPrice}
            languagePrefix={languagePrefix}
            translate={translate}
            sendingData={sendingData}
            promocodeValue={promocodeValue}
            emptyPreorder={isEmpty(this.state.orderAfterPromocode)}
          />
        </div>
      </StyledCheckoutPage>
    );
  }
}

const mapStateToProps = (state) => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  cart: state.cart ? state.cart : null,
  categories: state.categories ? state.categories : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  waitSms: state.waitSms ? state.waitSms : null,
  smsValid: state.smsValid ? state.smsValid : null,
  user: state.user ? state.user : null,
  form: state.form ? state.form : null,
  stores: state.stores ? state.stores : null,
  paymentMethods: state.paymentMethods ? state.paymentMethods : null,
  deliveryMethods: state.deliveryMethods ? state.deliveryMethods : null,
  order: state.order ? state.order : null,
  deferredOrder: state.deferredOrder ? state.deferredOrder : null,
  settings: state.settings ? state.settings : null,
  timeSlots: state.settings ? state.settings.timeslots : null,
  accessories: state.accessories ? state.accessories : null,
  isMobile: state.isMobile,
  promocodeValue: state.promocodeValue,
  deliveryZones: state.deliveryZones ? state.deliveryZones : null,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);

const StyledCheckoutPage = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --disabled-text-color: #313131;
  
  .checkout {
    &__header {
      height: 3.2rem;
    }
    
    &__content {
      max-width: 470px;
      width: 100%;
      padding: 0 15px 5rem;
      margin: 0 auto;
    }
    
    &__heading {
      color: var(--text-color);
      font-weight: 100;
      font-size: 1.4rem;
      margin: 2.4rem 0 1.4rem;
      text-align: center;
    }
    
    &__step-heading-wrap {
      display: flex;
      align-items: center;
      
      & path {
        stroke: var(--disabled-text-color);
      }
      
      &--active {
        & path {
          stroke: var(--active-text-color);
        }
        
        & .checkout__step-heading {
          color: var(--active-text-color);
        }
      }
      
      &--checked {
        & path {
          stroke: var(--text-color);
        }
        
        & .checkout__step-heading {
          color: var(--text-color);
        }
      }
    }
    
    &__step-heading {
      text-transform: none;
      font-weight: 200;
      padding: 0 0 0 .7rem;
      color: var(--disabled-text-color);
      font-size: 1.4rem;
    }
  }
  
@media all and (min-width: 768px) {
  .checkout {
    &__header {
      height: 250px;
      text-align: center;
      background: radial-gradient(640.48px at 50.41% 50.71%, rgba(0, 0, 0, 0.1) 0%, #000000 100%),
                  url(${(props) => (props.headerImage ? props.headerImage : 'null')});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
`;
