/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, useLocation } from 'react-router-dom';

const SeoRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const canonicalUrl = `https://budusushi.ua${location.pathname}`;

  // Define alternate language URLs
  const hrefFangs = [
    { lang: 'ru-UA', url: canonicalUrl },
    { lang: 'uk-UA', url: `https://budusushi.ua/ua${location.pathname}` },
  ];

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Helmet>
            <link rel="canonical" href={canonicalUrl} />
            {hrefFangs.map(({ lang, url }) => (
              <link key={lang} rel="alternate" hrefLang={lang} href={url} />
            ))}
          </Helmet>
          {Component ? <Component {...props} /> : null}
        </>
      )}
    />
  );
};

export default SeoRoute;
